import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-warning-pop',
  templateUrl: './warning-pop.component.html',
  styleUrls: ['./warning-pop.component.css']
})
export class WarningPopComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
