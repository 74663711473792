import { SpinnerService } from '@accubits/spinner';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { CompanyService } from '../services/company.service';
import { DataService } from '../services/data.service';
import { UserApiService } from '../services/user-api.service';

@Component({
  selector: 'app-view-edd',
  templateUrl: './view-edd.component.html',
  styleUrls: ['./view-edd.component.css']
})
export class ViewEddComponent implements OnInit, OnChanges {
  viewDoc: any = [];
  @Input() isQuestions:any;
  questionList: any = [];
  loadUrl = false;
  our_viewer = 'google';
  pdfViewer = false;
  officeExtensions = ['ppt', 'pptx', 'doc', 'docx', 'xls', 'xlsx']
  url;
  constructor(private comService: CompanyService,
    private spinner: SpinnerService,
    private toastr: ToastrService,
    public dataService: DataService,
    public apiService: UserApiService,
  ) { }

  ngOnInit() {
    // this.goLink();
      this.getQuestions();
  }
  getQuestions(){
    this.spinner.show();
    this.apiService.functionGET(`admin/edd/questions/${this.dataService.viewEdd.id}`).subscribe((res) => {
          if (res['status']) {
            try {
              this.questionList = res.data.rows;
            } catch (e) {
            }
            this.spinner.hide();
          }
        }, (err) => {
          if (err.message && err.message !== 'No details found') {
            this.toastr.error(err.message);
          } else if (err.errors) {
            for (const item of err.errors) {
              this.toastr.error(item.msg);
            }
          }
          this.spinner.hide();
        }
    );
  }

ngOnChanges(){
 

}
}
