import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/observable/throw';
import {API_URL} from '../../../config';

@Injectable()
export class KycService {

  constructor(private http: HttpClient) {

  }

  listing(data): Observable<any> {
    return this.http.post(`${API_URL}/admin/kyc/getCompanyList`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  approve(data): Observable<any> {
    return this.http.post(`${API_URL}/admin/kyc/approveCompany`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }
  approveAccount(data): Observable<any> {
    return this.http.patch(`${API_URL}/admin/account/updateStatus`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }
  reject(data): Observable<any> {
    return this.http.post(`${API_URL}/admin/kyc/rejectCompany`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  suspend(data): Observable<any> {
    return this.http.post(`${API_URL}/admin/kyc/suspendCompany`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  getGetIdUrl(data,guid): Observable<any> {
    return this.http.post(`${API_URL}/corporate/kyc/shareVerificationLink?guid=${guid}`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }


  addEdd(data): Observable<any> {
    return this.http.post(`${API_URL}/admin/kyc/requestAdditionalDocs`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  getDetails(data): Observable<any> {
    return this.http.get(`${API_URL}/admin/company/getDetails?id=${data}`)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }
  getAccDetails(data): Observable<any> {
    return this.http.get(`${API_URL}/admin/account/getDetails?guid=${data}`)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  sendVerify(data): Observable<any> {
    return this.http.post(`${API_URL}/admin/corporates/kyc/sendVerificationLink`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }
  verifyDoc(data): Observable<any> {
    return this.http.post(`${API_URL}//admin/company/verifyDocuments`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }
  getCountry(data): Observable<any> {
    return this.http.get(`${API_URL}/master/getCountryList`)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  getSignedUrl(data): Observable<any> {
    return this.http.post(`${API_URL}/admin/getSignedFileUrl`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  requestPre(data): Observable<any> {
    return this.http.post(`${API_URL}/admin/company/requestTransactionSigner`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  checkResponse(response: any) {
    const results = response;
    if (results['success']) {
      return results;
    } else {
      return results;
    }
  }
}
