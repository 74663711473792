import { Component, OnInit, Input,Output,EventEmitter } from '@angular/core';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent implements OnInit {
  @Output() closePopupOutput = new EventEmitter();
  @Output() deleteConfirm = new EventEmitter();
  questionSentenseText='';
  @Input() set questionSentence(data) {
    this.questionSentenseText=data;
  }
  constructor() { }
 
  ngOnInit() {
  }
  closePopup(){
     this.closePopupOutput.emit();
  }
  confirmPopup(){
    this.deleteConfirm.emit();
  }
}
