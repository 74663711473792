//****staging/master api url****//
export const API_URL = 'https://intech-api.accubits.com/v1';
export const file_url = 'https://intech-api.accubits.com';

export const kycRedirectionURL='https://intech.accubits.com/userLogin/organization';
//****UAT api url****//
// export const API_URL = 'https://intech-api-uat.devtomaster.com/v1';
// export const file_url = 'https://intech-api-uat.devtomaster.com';


// //****ngrok url for testing purpose****//
//  export const API_URL = 'https://b747-14-192-29-177.ngrok.io/v1';

// // //****Localhost url for testing purpose****//
//  export const API_URL = 'http://localhost:3030/v1';
