import { Component, OnInit, Input } from '@angular/core';
import { CompanyService } from '../../services/company.service';
import { SpinnerService } from '@accubits/spinner';
import { ToastrService } from 'ngx-toastr';
import { DataService } from '../../services/data.service';
import { UserApiService } from '../../services/user-api.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-view-doc',
  templateUrl: './view-doc.component.html',
  styleUrls: ['./view-doc.component.css']
})
export class ViewDocComponent implements OnInit {
  viewDoc: any = [];
  // @Input() isQuestions:any;
  questionList: any = [];
  loadUrl = false;
  our_viewer = 'google';
  pdfViewer = false;
  officeExtensions = ['ppt', 'pptx', 'doc', 'docx', 'xls', 'xlsx']
  url;
  @Input() set dataUrl(data) {
    this.url = data.url;
    let fileparts = this.url.split('?')[0].split('.');
    let fileExtension = fileparts.pop();
    if (fileExtension === 'pdf') {
      this.pdfViewer = true;
    } else if (this.officeExtensions.includes(fileExtension)) {

      this.our_viewer = 'office'
    }
    // switch(fileExtension){
    //   case 'pdf':
    //     this.pdfViewer = true;
    //     break;
    //     case 'docx':
    //       case 'docx':
    //       this.pdfViewer = true;
    //       break;

    // }
  }
  constructor(private comService: CompanyService,
    private spinner: SpinnerService,
    private toastr: ToastrService,
    public dataService: DataService,
    public apiService: UserApiService,
    private domSanitizer: DomSanitizer,
  ) { }

  ngOnInit() {
    // this.goLink();
    // if(this.isQuestions){
    //   this.getQuestions();
    // }
  }
  // getQuestions(){
  //   this.spinner.show();
  //   this.apiService.functionGET(`admin/edd/questions/${this.isQuestions}`).subscribe((res) => {
  //         if (res['status']) {
  //           try {
  //             this.questionList = res.data.rows;
  //           } catch (e) {
  //           }
  //           this.spinner.hide();
  //         }
  //       }, (err) => {
  //         if (err.message && err.message !== 'No details found') {
  //           this.toastr.error(err.message);
  //         } else if (err.errors) {
  //           for (const item of err.errors) {
  //             this.toastr.error(item.msg);
  //           }
  //         }
  //         this.spinner.hide();
  //       }
  //   );
  // }
  goLink() {
    const data = {
      file: this.dataService.viewDoc.file
    };
    this.spinner.show();
    this.loadUrl = false;
    this.comService.getSignedUrl(data).subscribe((res) => {
      if (res['status']) {
        this.spinner.hide();
        this.viewDoc = res['data'];
        this.url = res.data.url;
        setTimeout(() => {
          this.loadUrl = true;
        }, 1000);
        window.open(res['data']['url'], '_blank');
        /*let a = document.createElement('a');
        a.href = res['data']['url']
        a.download = 'fileName';
        a.click();*/
      }
    }, (err) => {
      if (err.message) {
        this.toastr.error(err.message);
      } else if (err.errors) {
        for (const item of err.errors) {
          this.toastr.error(item.msg);
        }
      }
      this.spinner.hide();
    }
    );
  }

}
