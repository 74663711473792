import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appTextLengthLimiter]'
})
export class TextLengthLimiterDirective {

  constructor(private el: ElementRef) { }

  @Input() inputMaxLength: number;
  regexStr = '^[0-9 _- ]*$:'



  @HostListener('keyPress', ['$event']) onKeyPress(event) {
    let e = <KeyboardEvent>event;
    return new RegExp(this.regexStr).test(event.key)

  }


  @HostListener('paste', ['$event']) blockPaste(event) {
    this.validateFields(event);

  }

  validateFields(event: ClipboardEvent) {
    event.preventDefault();
    const pasteData = event.clipboardData.getData('text/plain').replace('/[0-9 _- ]/g', '');
    document.execCommand('insertHTML', false, pasteData);
  }
}
