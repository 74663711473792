import {Injectable} from '@angular/core';

@Injectable()
export class Utils {
  emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,4}))$/;
  passRegEx = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})');
  phoneRegEx = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

  constructor() {
  }

  emailValidator(email) {
    if (this.emailRegEx.test(email)) {
      return true;
    } else {
      return false;
    }
  }

  passwordValidator(pass) {
    if (this.passRegEx.test(pass)) {
      return true;
    } else {
      return false;
    }
  }

  phoneValidator(phone) {
    if (phone && (phone.length > 9 && phone.length <= 15)) {
      return true;
    } else {
      return false;
    }
  }

  formatDate(date, format) {
    const dateSplitted = date.split('-');
    return dateSplitted[2] + '-' + dateSplitted[1] + '-' + dateSplitted[0];
    // let dd = date.getDate();
    // let mm = date.getMonth() + 1;
    // const yyyy = date.getFullYear();
    // let formatedDate: string;
    // if (dd < 10) {
    //   dd = '0' + dd;
    // }
    //
    // if (mm < 10) {
    //   mm = '0' + mm;
    // }
    // switch (format) {
    //   case 'yyyy-mm-dd':
    //     formatedDate = yyyy + '-' + mm + '-' + dd;
    //     break;
    //   case 'mm-dd-yyyy':
    //     formatedDate = mm + '-' + dd + '-' + yyyy;
    //     break;
    //   case 'yyyy/mm/dd':
    //     formatedDate = yyyy + '/' + mm + '/' + dd;
    //     break;
    //   default:
    //     break;
    // }
    // return formatedDate;
  }
}
