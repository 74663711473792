import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/observable/throw';
import {API_URL} from '../../../config';

@Injectable()
export class LogoutService {

  constructor(private http: HttpClient) {

  }

  logoutUser(): Observable<any> {
    return this.http.post(`${API_URL}/user/logout`, '')
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  logoutAdmin(): Observable<any> {
    return this.http.post(`${API_URL}/admin/logout`, '')
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }
  logoutReseller(): Observable<any> {
    return this.http.post(`${API_URL}/reseller/logout`, '')
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  checkResponse(response: any) {
    const results = response;
    if (results['success']) {
      return results;
    } else {
      return results;
    }
  }
}
