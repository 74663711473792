import {Injectable} from '@angular/core';
import {HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { switchMap,filter ,take, catchError } from 'rxjs/operators';
import {Observable} from 'rxjs/Observable';
import {DataService} from '../services/data.service';
import {AuthenticationService} from '../services/authentication.service';
import {Router} from '@angular/router';
import { throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import 'rxjs/add/operator/do';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private router: Router,
              private dataService: DataService,
              private authService:AuthenticationService,
              private toastr: ToastrService) {
  }
  isRefreshing = false;
  refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  isAdmin = sessionStorage.getItem('isAdmin');
  isReseller = sessionStorage.getItem('isReseller');

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.indexOf('reseller/auth/tfa') != -1) {
      this.dataService.tfaPage = true;
    }
    if (req.url.indexOf('user/auth/generateAccessToken') !== -1) {
      return next.handle(req);
    }
    if (!this.dataService.auth.profile.name) {
      this.dataService.auth.token = sessionStorage.getItem('token');
    }
    if (!this.dataService.auth.token) {
      this.dataService.auth.token = sessionStorage.getItem('token');
    }
    const request = req.clone({
      headers: new HttpHeaders().append('Authorization', 'Bearer ' + this.dataService.auth.token)
    });
  
    return next.handle(request).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 401 && req.url.indexOf('/reseller/logout') == -1 && req.url.indexOf('/user/logout') == -1 && req.url.indexOf('/reseller/authenticate') == -1) {
            const isAdmin = sessionStorage.getItem('isAdmin');
            const isReseller = sessionStorage.getItem('isReseller');
            if (!this.isRefreshing) {
              this.isRefreshing = true;
              this.refreshTokenSubject.next(null);
              let data={
                refreshToken: sessionStorage.getItem('refreshToken')
              }
              return this.authService.userRefreshToken(data,isReseller).pipe(
                switchMap((token: any) => {
                  this.isRefreshing = false;
                  this.dataService.auth.token=token.data.accessToken;
                  sessionStorage.setItem('token', token.data.accessToken);
                  sessionStorage.setItem('refreshToken',token.data.refreshToken);
                  this.refreshTokenSubject.next(token);
                  return next.handle(this.addToken(request, token.data.accessToken));
                })).catch((error)=>{
                  if(!error.status){
                    this.isRefreshing = false;
                    this.goTOLogin();
                    this.toastr.error("Session Expired.");
                  }
                 
                  return throwError(error);
                })
          
            } else {
              return this.refreshTokenSubject.pipe(
                filter(token => token != null),
                take(1),
                switchMap(token => {
                  this.dataService.auth.token=token.data.accessToken;
                  sessionStorage.setItem('token', token.data.accessToken);
                  sessionStorage.setItem('refreshToken',token.data.refreshToken);
                  return next.handle(this.addToken(request, token.data.accessToken));
                })).catch((error)=>{
                  if(!error.status){
                    this.isRefreshing = false;
                    this.goTOLogin();
                  }
                  return throwError(error);
                })
            }

         

          }else{
         
            if(req.url.indexOf('/logout') !== -1){
              this.goTOLogin();
              return ;
            }
            return throwError(error);
          }
        }
      })
    );
    
  }
  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        'Authorization': `Bearer ${token}`
      }
    });
  }
  goTOLogin(){
    if (this.isAdmin == 'true') {
      this.router.navigateByUrl('/userLogin/admin');
    }else if (this.isReseller == 'true') {
      if(this.dataService.tfaPage){
        this.dataService.tfaPage = false;
        return;
      }
      if(window.location.pathname=="/userLogin/admin"){
        this.router.navigateByUrl('/userLogin/admin');
      }else{
        this.router.navigateByUrl('/userLogin/reseller');
      }
     
    } else {
      if(window.location.pathname=="/userLogin/admin"){
        this.router.navigateByUrl('/userLogin/admin');
      }else{
        this.router.navigateByUrl('/userLogin/login');
      }
     
    }
    this.dataService.clearAuth();
}
 
  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
    });
  }
}
