import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {DataService} from '../shared/services/data.service';
import {SpinnerService} from '@accubits/spinner';
import {ActivationService} from '../shared/services/activation.service';
@Component({
  selector: 'app-cancel',
  templateUrl: './cancel.component.html',
  styleUrls: ['./cancel.component.css']
})
export class CancelComponent implements OnInit {

  declined = false;
  success = false;
  id = false;
  errorMessage = 'Loading...';
  data = {
    paymentId: '',
    payerId: ''
  };
  header = {
    latitude: '',
    longitude: ''
  }

  constructor(private actService: ActivationService,
              private toastr: ToastrService,
              public dataService: DataService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private spinner: SpinnerService) {
  }

  ngOnInit() {

  }


  gotoDash() {
    this.router.navigateByUrl('/userLogin/details');
    window.close();
  }



}
