import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SwiperModule} from 'ngx-swiper-wrapper';
import {AngularFontAwesomeModule} from 'angular-font-awesome';
import {FormsModule} from '@angular/forms';
import {DataService} from './services/data.service';
import {Utils} from './common/utils';
import {AuthenticationService} from './services/authentication.service';
import {CorporateService} from './services/corporate.service';
import {KycService} from './services/kyc.service';
import {CompanyService} from './services/company.service';
import {DetailsService} from './services/details.service';
import {VerificationService} from './services/verification.service';
import {EmployeeService} from './services/employee.service';
import {SideBarComponent} from './side-bar/side-bar.component';
import {HeaderComponent} from './header/header.component';
import {RouterModule} from '@angular/router';
import {NamePipe} from './pipe/name.pipe';
import { UiSwitchModule } from 'ngx-toggle-switch';
import { Ng5SliderModule } from 'ng5-slider';
import { NgxDocViewerModule} from 'ngx-doc-viewer';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import {AlphaNumericDirective} from './directives/alphanumeric.directive';
import {NumberDirective} from './directives/numeric.directive';
import { WarningPopComponent } from './warning-pop/warning-pop.component';
import {NgxPaginationModule} from 'ngx-pagination';
import {ActivationService} from './services/activation.service';
import {LogoutService} from './services/logout.service';
import { ViewDocComponent } from './components/view-doc/view-doc.component';
import { from } from 'rxjs';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PermissionService } from './services/permission.service';
import { ViewEddComponent } from './view-edd/view-edd.component';

import { AgmCoreModule } from '@agm/core';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { DecimalInputDirective } from './directives/decimal-input.directive';
import {PaginationPageCountComponent} from './pagination-page-count/pagination-page-count.component';
import { PasswordStrengthIndicatorComponent } from './password-strength-indicator/password-strength-indicator.component';
import { LargeTextLoaderComponent } from './large-text-loader/large-text-loader.component';
import { SmallTextLoaderComponent } from './small-text-loader/small-text-loader.component';
import { TextLengthLimiterDirective } from './directives/text-length-limiter.directive';
import { SafePipe } from './pipe/safe.pipe';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { InputEmptyDirective } from './directives/inputEmpty.directive';
import { PopupComponent } from './popup/popup.component';
import { ConfirmPopupComponent } from './confirm-popup/confirm-popup.component';

@NgModule({
  providers: [
    DataService,
    AuthenticationService,
    CorporateService,
    CompanyService,
    DetailsService,
    VerificationService,
    EmployeeService,
    ActivationService,
    KycService,
    LogoutService,
    Utils,
    PermissionService
  ],
  declarations: [
    SideBarComponent,
    HeaderComponent,
    NamePipe,
    AlphaNumericDirective,
    InputEmptyDirective,
    NumberDirective,
    WarningPopComponent,
    ViewDocComponent,
    ViewEddComponent,
    DecimalInputDirective,
    PaginationPageCountComponent,
    PasswordStrengthIndicatorComponent,
    LargeTextLoaderComponent,
    SmallTextLoaderComponent,
    TextLengthLimiterDirective,
    SafePipe,
    PageNotFoundComponent,
    ConfirmDialogComponent,
    PopupComponent,
    ConfirmPopupComponent
  ],
  imports: [
    CommonModule,
    SwiperModule,
    FormsModule,
    RouterModule,
    AngularFontAwesomeModule,
    UiSwitchModule,
    Ng5SliderModule,
    AngularMyDatePickerModule,
    NgxPaginationModule,
    NgxDocViewerModule,
    PdfViewerModule,
    GooglePlaceModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDaMfyHfMmSFy0lvM6zZ8h9TZ3t4JuDJL0'
    })
  ],
  exports: [
    SwiperModule,
    SideBarComponent,
    HeaderComponent,
    AlphaNumericDirective,
    InputEmptyDirective,
    NumberDirective,
    FormsModule,
    NamePipe,
    UiSwitchModule,
    Ng5SliderModule,
    AngularMyDatePickerModule,
    NgxPaginationModule,
    WarningPopComponent,
    ViewDocComponent,
    NgxDocViewerModule,
    PdfViewerModule,
    AgmCoreModule,
    GooglePlaceModule,
    ViewEddComponent,
    DecimalInputDirective,
    PaginationPageCountComponent,
    PasswordStrengthIndicatorComponent,
    LargeTextLoaderComponent,
    SmallTextLoaderComponent,
    TextLengthLimiterDirective,
    SafePipe,
    PageNotFoundComponent,
    ConfirmDialogComponent,
    ConfirmPopupComponent,
    PopupComponent
  ]
})
export class SharedModule {

}
