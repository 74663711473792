import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/observable/throw';
import {API_URL} from '../../../config';

@Injectable()
export class CorporateService {

  constructor(private http: HttpClient) {

  }

  listing(params): Observable<any> {
    return this.http.get(`${API_URL}/corporate/getList?limit=${params.size}&page=${params.page}&type=${params.type}`)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  
  getResellerCorporates(params): Observable<any> {
    return this.http.get(`${API_URL}/reseller/corporates/list?limit=${params.size}&page=${params.page}&type=${params.type}`)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }
  getDocs(): Observable<any> {
    return this.http.get(`${API_URL}/corporate/getRegistrationDocs`)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  getAddDocs(): Observable<any> {
    return this.http.get(`${API_URL}/corporate/getAdditionalDocs`)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  getRequestedDocs(): Observable<any> {
    return this.http.get(`${API_URL}/corporate/docList`)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }


  getSignedUrl(data): Observable<any> {
    return this.http.post(`${API_URL}/user/getSignedFileUrl`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  addAsCompanyAdmin(data): Observable<any> {
    return this.http.post(`${API_URL}/corporate/addCompanyAdmin`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  removeAsCompanyAdmin(data): Observable<any> {
    return this.http.post(`${API_URL}/corporate/removeCompanyAdmin`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  deleteCompany(data): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: data
    };
    return this.http.delete(`${API_URL}/corporate/remove`, options)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  deleteUBO(data): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: data
    };
    return this.http.delete(`${API_URL}/corporate/removeUbo`, options)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }


  getDetails(data): Observable<any> {
    return this.http.get(`${API_URL}/corporate/getDetails?id=${data}`)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  getAddress(data): Observable<any> {
    return this.http.get(`${API_URL}/master/getAddressProofTypes`)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  getCountry(data): Observable<any> {
    return this.http.get(`${API_URL}/master/getCountryList`)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  addCorporate(data): Observable<any> {
    return this.http.post(`${API_URL}/corporate/add`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  addResellerCorporate(data): Observable<any> {
    return this.http.post(`${API_URL}/reseller/corporate/add`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  editResellerCorporate(data): Observable<any> {
    return this.http.put(`${API_URL}/reseller/updateCorporate`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }


  editCorporate(data): Observable<any> {
    return this.http.put(`${API_URL}/corporate/updateDetails`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }
  getTransactionList(data): Observable<any> {
    return this.http.get(`${API_URL}/company/transactionSigner/getList`)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }
  checkResponse(response: any) {
    const results = response;
    if (results['success']) {
      return results;
    } else {
      return results;
    }
  }
}
