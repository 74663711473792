import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/observable/throw';
import {API_URL} from '../../../config';

@Injectable()
export class CompanyService {

  constructor(private http: HttpClient) {

  }

  listing(data): Observable<any> {
    return this.http.post(`${API_URL}/admin/company/getList`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }
  terminateCompany(data): Observable<any> {
    return this.http.post(`${API_URL}/admin/company/terminate`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }
  unblockCompany(data): Observable<any> {
    return this.http.post(`${API_URL}/admin/company/unBlock`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }
  blockCompany(data): Observable<any> {
    return this.http.post(`${API_URL}/admin/company/block`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

    

  blockIbanAccount(data){
    return this.http.post(`${API_URL}/admin/company/blockIbanAccount`, data)
    .map(this.checkResponse)
    .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  unBlockIbanAccount(data){
    return this.http.post(`${API_URL}/admin/company/unblockIbanAccount`, data)
    .map(this.checkResponse)
    .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  deleteCompany(data): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: data
    };

    return this.http.delete(`${API_URL}/admin/company/remove`, options)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  getDetails(data): Observable<any> {
    return this.http.get(`${API_URL}/admin/company/getDetails?id=${data}`)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  blockCurrencyAccount(guid): Observable<any> {
    return this.http.get(`${API_URL}/admin/company/blockCurrencyAccount?guid=${guid}`)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  unblockCurrencyAccount(guid): Observable<any> {
    return this.http.get(`${API_URL}/admin/company/unblockCurrencyAccount?guid=${guid}`)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  getKYCdocs(id){
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    return this.http.get(`${API_URL}/admin/corporates/kyc/getApplicationReport?id=${id}`,{ headers: headers, responseType: 'blob' })
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }



  getAddress(data): Observable<any> {
    return this.http.get(`${API_URL}/master/getAddressProofTypes`)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }
  getResellerList(): Observable<any> {
    return this.http.get(`${API_URL}/admin/reseller/list`)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  payLater(data): Observable<any> {
    return this.http.post(`${API_URL}/admin/company/payLater`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  setActivationFee(data): Observable<any> {
    return this.http.post(`${API_URL}/admin/company/setActivationFee`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  sendActivationFee(data): Observable<any> {
    return this.http.post(`${API_URL}/admin/company/sendPaymentRequest`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  getCurrency(): Observable<any> {
    return this.http.get(`${API_URL}/master/getCurrency`)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  setCurrency(data): Observable<any> {
    return this.http.post(`${API_URL}/admin/company/mapIbanCurrencies`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  setIban(data): Observable<any> {
    return this.http.post(`${API_URL}/admin/company/setIbanAccountTypes`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  uploadDocument(data): Observable<any> {
    return this.http.post(`${API_URL}/admin/company/uploadCorporateDocument`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  setBankDetails(data): Observable<any> {
    return this.http.post(`${API_URL}/admin/company/setBankDetails`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  getCountry(data): Observable<any> {
    return this.http.get(`${API_URL}/master/getCountryList`)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  searchForCompany(data): Observable<any>{
    return this.http.post(`${API_URL}/duns/advanceCompanySearch`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  addCompany(data): Observable<any> {
    return this.http.post(`${API_URL}/admin/company/setBasicDetails`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  requestPre(data): Observable<any> {
    return this.http.post(`${API_URL}/admin/company/requestTransactionSigner`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  getSignedUrl(data): Observable<any> {
    return this.http.post(`${API_URL}/admin/getSignedFileUrl`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  sendVerify(data): Observable<any> {
    return this.http.post(`${API_URL}/admin/corporates/kyc/sendVerificationLink`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }
  verifyDoc(data): Observable<any> {
    return this.http.post(`${API_URL}//admin/company/verifyDocuments`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }
  setCount(data): Observable<any> {
    return this.http.post(`${API_URL}/admin/company/setCorporatesCount`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  addCorporate(data): Observable<any> {
    return this.http.post(`${API_URL}/admin/company/addCorporate`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  verifyVat(data): Observable<any> {
    return this.http.post(`${API_URL}/admin/company/verifyVATNumber`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  getCompanyComissionDetails(companyId,commissionOf,vendorId): Observable<any> {
    const header = new HttpHeaders({
      Authorization: sessionStorage.getItem('token')
    })
    let apiEndPoint=commissionOf=="company" ? '/admin/company/getCompanyFees?guid=':'/admin/reseller/getResellerFees?guid='
    return this.http.get(`${API_URL}${apiEndPoint}${companyId}&vendorId=${vendorId}`, { headers: header})
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }
  addNewService(data): Observable<any> {    
    return this.http.post(`${API_URL}/admin/company/addCompanyFees`,data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  updateCompanyService(data): Observable<any> {    
    return this.http.post(`${API_URL}/admin/company/addCompanyFees`,data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  addNewResellerService(data): Observable<any> {    
    return this.http.post(`${API_URL}/admin/reseller/addResellerFees`,data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  checkResponse(response: any) {
    const results = response;
    if (results['success']) {
      return results;
    } else {
      return results;
    }
  }
}
