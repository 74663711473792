import {Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
  selector: 'input[inputTypeText]'
})
export class InputEmptyDirective {

  constructor(private _el: ElementRef) {
  }

  @HostListener('input', ['$event']) onInputChange($event) {
    const value = this._el.nativeElement.value;
        // const regex = /^\s*$/;
        let spaceRegex = /^\s+$/i; 
        return spaceRegex.test(value) ? $event.preventDefault() : null;

        // if (!!value) {
        //     if (value.match(spaceRegex)) { 
        //       event.stopPropagation();
        //     }
        // }
  }

}
