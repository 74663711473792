import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'createAvatar'
})
export class NamePipe implements PipeTransform {

  colorArray = ['#ff4545',
    '#697fff',
    '#44cf7c',
    '#c4b74f',
    '#FFC107',
    '#7f45d1',
    '#91b008',
    '#48359a',
    '#6296a8',
    '#ff5b93'];

  transform(value, args?: string): any {
    if (!value) {
      return value;
    }
    if (value === 'changeBGColor') {
      return this.colorArray[Math.floor((Math.random() * 10))];
    }
    const stringVal = value.split(' ');
    let avatar = '';
    for (const item in stringVal) {
      if (Number(item) < 2) {
        avatar = avatar + stringVal[item].charAt(0);
      }
    }
    return avatar;
  }

}
