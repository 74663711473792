import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthenticationGuard} from './shared/authgaurd/auth.guard';
import {PaymentResponseComponent} from './payment-response/payment-response.component';
import {CancelComponent} from './cancel/cancel.component';
import {PaymentInitiateComponent} from './payment-initiate/payment-initiate.component';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';

const routes: Routes = [
  {
    path: 'userLogin',
    loadChildren: '../app/authentication/authentication.module#AuthenticationModule'
  },
  {
    path: '',
    loadChildren: '../app/landing/landing.module#LandingModule'
  },
  {
    path: 'dashboard',
    loadChildren: './dashboard/dashboard.module#DashboardModule',
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'resellerDashboard',
    loadChildren: './reseller/reseller.module#ResellerModule',
    canActivate: [AuthenticationGuard]
  },
  // {
  //   path: 'adminDashboard',
  //   loadChildren: './admin/admin.module#AdminModule',
  //   canActivate: [AuthenticationGuard]
  // },
  {
    path: 'verification',
    loadChildren: '../app/verification/verification.module#VerificationModule'
  },
  {
    path: 'paymentResponse',
    component: PaymentResponseComponent
  },
  {
    path: 'cancel',
    component: CancelComponent
  },
  {
    path:'authorizePayment',
    component: PaymentInitiateComponent
  },
  {
    path:'**',
    pathMatch: 'full',
    component: PageNotFoundComponent
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
