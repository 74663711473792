import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpBackend} from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/observable/throw';
import {API_URL} from '../../../config';
import { DataService } from './data.service';

@Injectable()
export class AuthenticationService {
  private http: HttpClient;
  private dataService:DataService;

  constructor(handler: HttpBackend) {
    this.http = new HttpClient(handler);
  }


  login(data): Observable<any> {
    return this.http.post(`${API_URL}/user/authenticate`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }
  acceptTc(data): Observable<any> {
    return this.http.post(`${API_URL}/user/acceptTC`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  loginAdmin(data): Observable<any> {
    return this.http.post(`${API_URL}/admin/authenticate`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  userRefreshToken(data,isReseller): Observable<any> {
    // alert(this.dataService.auth.token)
    // const headers = new HttpHeaders({
    //   'Authorization':'Bearer ' + this.dataService.auth.token,
    //   'No-Auth': 'True'
    // });
    let url = isReseller ? '/reseller/auth/generateAccessToken':'/user/auth/generateAccessToken'; 
    return this.http.post(`${API_URL}${url}`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  register(data): Observable<any> {
    return this.http.post(`${API_URL}/company/register`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  resendOtp(data): Observable<any> {
    return this.http.post(`${API_URL}/user/auth/resendOtp`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }


  resendRegisterOtp(data): Observable<any> {
    return this.http.post(`${API_URL}/company/resendOtp`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }


  verifyLoginOtp(data, header): Observable<any> {
    const headers = new HttpHeaders({
      latitude: header.latitude,
      longitude: header.longitude,
      macAddress: ''
    });
    return this.http.post(`${API_URL}/user/auth/verifyOtp`, data, {headers: headers})
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }
  verifyTfaCode(data, header): Observable<any> {
    const headers = new HttpHeaders({
      latitude: header.latitude,
      longitude: header.longitude,
      macAddress: ''
    });
    return this.http.post(`${API_URL}/user/auth/tfa/verify`, data, {headers: headers})
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  verifyRegisterOtp(data, header): Observable<any> {
    const headers = new HttpHeaders({
      latitude: header.latitude,
      longitude: header.longitude,
      macAddress: ''
    });
    return this.http.post(`${API_URL}/company/verifyOtp`, data, {headers: headers})
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  verifyForgetOtp(data): Observable<any> {
    return this.http.post(`${API_URL}/user/forgot/verifyEmailOtp`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }
  verifyReferralCode(data): Observable<any> {
    return this.http.get(`${API_URL}/user/verify/referal/${data}`)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  verifyResellerCode(data): Observable<any> {
    return this.http.get(`${API_URL}/reseller/verify/referral/${data}`)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }
  verifyAdminOtp(data): Observable<any> {
    return this.http.post(`${API_URL}/admin/auth/verifyOtp`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  forgotVerifyEmail(data): Observable<any> {
    return this.http.post(`${API_URL}/user/forgot/verifyEmail`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  resendForgotOtp(data) {
    return this.http.post(`${API_URL}/user/forgot/resendEmailOtp`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  resendAdminOtp(data) {
    return this.http.post(`${API_URL}/admin/auth/resendOtp`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  resetPassword(data) {
    return this.http.post(`${API_URL}/user/forgot/addPassword`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }
  checkLink(data) {
    return this.http.post(`${API_URL}/user/checkLinkExpire`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }
  registerEmail(data): Observable<any> {
    return this.http.post(`${API_URL}/user/isValidEmail`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }
  getTcDetails(data): Observable<any> {
    return this.http.post(`${API_URL}/admin/company/getTermsAndConditions`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }
  otpEmail(data, header): Observable<any> {
    const headers = new HttpHeaders({
      latitude: header.latitude,
      longitude: header.longitude,
      macAddress: ''
    });
    return this.http.post(`${API_URL}/user/email/verifyOtp`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }
  registerPhone(data): Observable<any> {
    return this.http.post(`${API_URL}/user/isValidMobile`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }
  otpPhone(data, header): Observable<any> {
    const headers = new HttpHeaders({
      latitude: header.latitude,
      longitude: header.longitude,
      macAddress: ''
    });
    return this.http.post(`${API_URL}/user/mobile/verifyOtp`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }
  registerPassword(data): Observable<any> {
    return this.http.post(`${API_URL}/user/register`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }
  resendEmailOtp(data): Observable<any> {
    return this.http.post(`${API_URL}/user/email/resendOtp`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }
  resendPhoneOtp(data): Observable<any> {
    return this.http.post(`${API_URL}/user/mobile/resendOtp`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }
  setTFA(data): Observable<any> {
    return this.http.post(`${API_URL}/user/auth/tfa/setup`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }
  enableTfa(data): Observable<any> {
    return this.http.post(`${API_URL}/user/auth/tfa/enable`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }
  checkResponse(response: any) {
    const results = response;
    if (results['success']) {
      return results;
    } else {
      return results;
    }
  }
}
