import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-confirm-popup',
  templateUrl: './confirm-popup.component.html',
  styleUrls: ['./confirm-popup.component.css']
})
export class ConfirmPopupComponent implements OnInit {

  @Input() config = {
    confirmTxt: '',
    cancelTxt: ''
  }
  @Output() confirmation = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  emitResult(mode:boolean){
    this.confirmation.emit(mode);
  }

}
