import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/observable/throw';
import {API_URL} from '../../../config';
import { observableToBeFn } from 'rxjs/internal/testing/TestScheduler';

@Injectable()
export class DetailsService {


  constructor(private http: HttpClient) {
  }

  getCountry(data): Observable<any> {
    return this.http.get(`${API_URL}/master/getCountryList`)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }


  getAlreadyAddedUBOs(){
    return this.http.get(`${API_URL}/company/getSavedCorporates`)
    .map(this.checkResponse)
    .catch((error) => Observable.throw(error.error || 'Server error.'));
  
  }

  getPaymentDetails(guid){
    return this.http.get(`${API_URL}/admin/payments/getPaymentDetails?guid=${guid}`)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
    
  }
  getcompanyComprehensiveReport(companyId,format): Observable<any> {
    return this.http.get(`${API_URL}/duns/generateReport?companyId=${companyId}&format=${format}`)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  getBeneficiaryOwnerShipList(companyId): Observable<any> {
    return this.http.get(`${API_URL}/duns/fetchBeneficialOwner?companyId=${companyId}`)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  shareBeneficiaryOwnerShipList(data):Observable<any>{
    return this.http.post(`${API_URL}/duns/sendBeneficialOwnerReport`,data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  shareComprehensiveReport(data):Observable<any>{
    return this.http.post(`${API_URL}/duns/sendCompReport`,data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  getConstants(constantName): Observable<any>{
    return this.http.get(`${API_URL}/master/getConstants?data=${constantName}`)
    .map(this.checkResponse)
    .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  addRemarks(data): Observable<any> {
    return this.http.post(`${API_URL}/company/setRemarks`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  completedProcess(): Observable<any> {
    return this.http.post(`${API_URL}/company/completedCorporates`, '')
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  getAddress(data): Observable<any> {
    return this.http.get(`${API_URL}/master/getAddressProofTypes`)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  getAllCorporates(): Observable<any> {
    return this.http.get(`${API_URL}/corporate/getAll`)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  addCorporate(data): Observable<any> {
    return this.http.post(`${API_URL}/corporate/add`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  editCorporate(data): Observable<any> {
    return this.http.put(`${API_URL}/corporate/updateDetails`, data)
        .map(this.checkResponse)
        .catch((error) => Observable.throw(error.error || 'Server error.'));
  }


  verifyPhoneNumber(data): Observable<any> {
    return this.http.post(`${API_URL}/corporate/isValidMobile`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  verifyEmail(data): Observable<any> {
    return this.http.post(`${API_URL}/corporate/isValidEmail`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  verifyBasis(data): Observable<any> {
    return this.http.post(`${API_URL}/corporate/kyc/verify`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  verifyBasisEmail(data): Observable<any> {
    return this.http.post(`${API_URL}/corporate/kyc/sendVerificationLink`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  verifyIban(data): Observable<any> {
    return this.http.post(`${API_URL}/company/isIbanSupportCountry`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  getCurrency(): Observable<any> {
    return this.http.get(`${API_URL}/master/getCurrency`)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  uploadDocument(data): Observable<any> {
    return this.http.post(`${API_URL}/company/uploadCorporateDocument`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  setBankDetails(data): Observable<any> {
    return this.http.post(`${API_URL}/company/setBankDetails`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  setIban(data): Observable<any> {
    return this.http.post(`${API_URL}/company/setIbanAccountTypes`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  payLater(data): Observable<any> {
    return this.http.post(`${API_URL}/company/payLater`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  setCurrency(data): Observable<any> {
    return this.http.post(`${API_URL}/company/setIbanDetails`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  addCompany(data): Observable<any> {
    return this.http.post(`${API_URL}/company/setBasicDetails`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  verifyVat(data): Observable<any> {
    return this.http.post(`${API_URL}/company/verifyVATNumber`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  addType(data): Observable<any> {
    return this.http.post(`${API_URL}/company/addBusinessType`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  addOperational(data): Observable<any> {
    return this.http.post(`${API_URL}/company/addOperationalInformation`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  addBusiness(data): Observable<any> {
    return this.http.post(`${API_URL}/company/addBusinessDetails`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  setCount(data): Observable<any> {
    return this.http.post(`${API_URL}/company/setCorporatesCount`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  setUBOCount(data): Observable<any>{
    return this.http.post(`${API_URL}/company/setUbosCount`, data)
    .map(this.checkResponse)
    .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  addGlobalNewService(data): Observable<any> { 
    return this.http.post(`${API_URL}/admin/commission/addFeeType`,data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  updateGlobalServiceDetails(data): Observable<any> {
    return this.http.post(`${API_URL}/admin/commission/updateFeeType`, data)
    .map(this.checkResponse)
    .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  updateGlobalVendorServiceDetails(data): Observable<any> {
    return this.http.post(`${API_URL}/admin/commission/updateVendorFeeType`, data)
    .map(this.checkResponse)
    .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  getCommissionSummary(): Observable<any> {
    return this.http.get(`${API_URL}/admin/commission/commissionSummary`)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }


  getAllVendorType(): Observable<any> {
    return this.http.get(`${API_URL}/admin/commission/allVendors`)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  getVendorFessByID(id): Observable<any> {
    return this.http.get(`${API_URL}/admin/commission/getAllFeesVendor?vendorId=${id}`)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }





  checkResponse(response: any) {
    const results = response;
    if (results['success']) {
      return results;
    } else {
      return results;
    }
  }
}
