import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpBackend} from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/observable/throw';
import {API_URL} from '../../../config';

@Injectable()
export class VerificationService {
  private http: HttpClient;

  constructor(handler: HttpBackend) {
    this.http = new HttpClient(handler);
  }


  verifyWidgetToken(data): Observable<any> {
    return this.http.post(`${API_URL}/kyc/verifyWidgetToken`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  processCompleted(data): Observable<any> {
    return this.http.post(`${API_URL}/kyc/finish`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  checkResponse(response: any) {
    const results = response;
    if (results['success']) {
      return results;
    } else {
      return results;
    }
  }
}
