import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/observable/throw';
import {API_URL} from '../../../config';

@Injectable()
export class EmployeeService {

  constructor(private http: HttpClient) {

  }

  listing(data): Observable<any> {
    if(data.type==0){
      return this.http.get(`${API_URL}/employee/getList?size=${data.size}&page=${data.page}&type=${data.type}`)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
    }else{
      return this.http.get(`${API_URL}/employee/getSignatories?size=${data.size}&page=${data.page}&type=0`)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
    }
 
  }

  deleteCompany(data): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: data
    };
    return this.http.delete(`${API_URL}/employee/remove`, options)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }


  listingPages(): Observable<any> {
    return this.http.get(`${API_URL}/master/getUserPages`)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  getCountry(data): Observable<any> {
    return this.http.get(`${API_URL}/master/getCountryList`)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  getDetails(data): Observable<any> {
    return this.http.get(`${API_URL}/employee/getDetails?id=${data}`)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  getAddress(data): Observable<any> {
    return this.http.get(`${API_URL}/master/getAddressProofTypes`)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  addEmployee(data): Observable<any> {
    return this.http.post(`${API_URL}/employee/add`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  editEmployee(data): Observable<any> {
    return this.http.put(`${API_URL}/employee/updateDetails`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  checkResponse(response: any) {
    const results = response;
    if (results['success']) {
      return results;
    } else {
      return results;
    }
  }
}
