import {Injectable} from '@angular/core';
import {HttpBackend, HttpClient, HttpHeaders} from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/observable/throw';
import {API_URL} from '../../../config';
import {DataService} from './data.service';

@Injectable()
export class ActivationService {

  // private http: HttpClient;

  constructor(private http: HttpClient,
              private dataService: DataService) {
    // this.http = new HttpClient(handler);
  }

  getSecret(data): Observable<any> {
    if (!this.dataService.auth.token) {
      this.dataService.auth.token = sessionStorage.getItem('token');
    }
    const headers = new HttpHeaders({
      latitude: data.latitude,
      longitude: data.longitude,
      macAddress: '',
      Authorization: 'Bearer ' + this.dataService.auth.token
    });
    return this.http.post(`${API_URL}/company/pay/getSecret`, '', {headers: headers})
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error || 'Server error.'));
  }

  getPaypalLink() {
    if (!this.dataService.auth.token) {
      this.dataService.auth.token = sessionStorage.getItem('token');
    }
    const headers = new HttpHeaders({
      latitude: this.dataService.header.latitude,
      longitude: this.dataService.header.longitude,
      macAddress: '',
      Authorization: 'Bearer ' + this.dataService.auth.token
    });
    return this.http.post(`${API_URL}/company/paypal/pay`, '', {headers: headers})
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error || 'Server error.'));
  }

  checkPaypalResponse(data, header) {
    const headers = new HttpHeaders({
      latitude: header.latitude,
      longitude: header.longitude
    });
    return this.http.post(`${API_URL}/company/paypal/getStatus`, data, {headers: headers})
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error || 'Server error.'));
  }
  bankTransfer(): Observable<any> {
    if (!this.dataService.auth.token) {
      this.dataService.auth.token = sessionStorage.getItem('token');
    }
    const headers = new HttpHeaders({
      latitude: this.dataService.header.latitude,
      longitude: this.dataService.header.longitude,
      macAddress: '',
      // Authorization: 'Bearer ' + this.dataService.auth.token
    });
    return this.http.post(`${API_URL}/company/completedBanktransfer`, '', {headers: headers})
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  checkResponse(response: any) {
    const results = response;
    if (results['success']) {
      return results;
    } else {
      return results;
    }
  }
}
