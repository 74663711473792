import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/observable/throw';
import {API_URL} from '../../../config';


@Injectable({
  providedIn: 'root'
})
export class UserApiService {
  constructor(private http: HttpClient) {
  }

  functionPOST(api, data): Observable<any> {
    const URL = API_URL + '/'  + api;
    return this.http.post(URL, data)
        .map(this.checkResponse)
        .catch((error) => Observable.throw(error.error || 'Server error.'));
  }
  functionDOWN(api, data): Observable<any> {
    const URL = API_URL + '/'  + api;
    const header = new HttpHeaders({
        Authorization: sessionStorage.getItem('token')
      })
    return  this.http.post<Blob>(URL, data, { headers: header, responseType: 'blob' as 'json' })
        .map(this.checkResponse)
        .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  functionPUT(api, data): Observable<any> {
    const URL = API_URL + '/'  + api;
    return this.http.put(URL, data)
        .map(this.checkResponse)
        .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  functionDelete(api, data?): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: sessionStorage.getItem('token')
      }),
      body: data
    };

    const URL = API_URL + '/' + api;
    return this.http.delete(URL, httpOptions)
        .map(this.checkResponse)
        .catch((error) => Observable.throw(error.error || 'Server error.'));
  }
  functionGET(api): Observable<any> {
    const URL = API_URL + '/'  + api;
    return this.http.get(URL)
        .map(this.checkResponse)
        .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

  getFile(api): Observable<any> {
    const URL = API_URL + '/'  + api;
    return this.http.get(URL, { responseType: 'blob'});
  }
  

  checkResponse(response: any) {

    const results = response;

    if (results.status) {
      return results;
    } else {
      return results;
    }
  }
  handleError(error) {

  }

  getCurrency(): Observable<any> {
    return this.http.get(`${API_URL}/master/getCurrency`)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }
  addCurrency(data): Observable<any> {
    return this.http.post(`${API_URL}/company/addNewCurrency`, data)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }
  addIban(mappings): Observable<any> {
    
    return this.http.post(`${API_URL}/company/setMultipleIban`, mappings)
      .map(this.checkResponse)
      .catch((error) => Observable.throw(error.error || 'Server error.'));
  }

}
