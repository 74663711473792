import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'app-pagination-page-count',
  templateUrl: './pagination-page-count.component.html',
  styleUrls: ['./pagination-page-count.component.css']
})
export class PaginationPageCountComponent implements OnInit {
  selectionCount=10;
  @Output() listCountChange = new EventEmitter();
  @Input() set listCount(data) {
    this.selectionCount=data;
  }

  constructor() { }

  ngOnInit() {
  }
  updateValueChange(){
    this.listCountChange.emit(this.selectionCount);
  }

}
