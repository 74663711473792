import {Component, OnChanges, Input, SimpleChange,OnInit} from '@angular/core';  

@Component({
  selector: 'app-password-strength-indicator',
  templateUrl: './password-strength-indicator.component.html',
  styleUrls: ['./password-strength-indicator.component.css']
})
export class PasswordStrengthIndicatorComponent implements OnInit {

  @Input() public passwordToCheck: string;  
  @Input() public barLabel: string;  
  passwordStrengthText:any;
  passwordStatusColor:any;
  bar0: string;  
  bar1: string;  
  bar2: string;  
  bar3: string;  
  bar4: string;  

  ngOnInit() {
  }

  private colors = ['#F00', '#F90', '#FF0', '#9F0', '#0F0'];  

  private static measureStrength(pass: string) {  
      let score = 0;  
      // award every unique letter until 5 repetitions  
      let letters = {};  
      for (let i = 0; i< pass.length; i++) {  
      letters[pass[i]] = (letters[pass[i]] || 0) + 1;  
      score += 5.0 / letters[pass[i]];  
      }  
      // bonus points for mixing it up  
      let variations = {  
      digits: /\d/.test(pass),  
      lower: /[a-z]/.test(pass),  
      upper: /[A-Z]/.test(pass),  
      nonWords: /\W/.test(pass),  
      };  

      let variationCount = 0;  
      for (let check in variations) {  
      variationCount += (variations[check]) ? 1 : 0;  
      }  
      score += (variationCount - 1) * 10;  
      return Math.trunc(score);  
  }
private getColor(score: number) {  
  let idx = 0;  
  if (score > 90) {  
    this.passwordStrengthText='Very strong';
    idx = 4;  
  } else if (score > 70) {  
    this.passwordStrengthText='Strong';
    idx = 3;  
  } else if (score >= 40) {  
    this.passwordStrengthText='Good';
    idx = 2;  
  } else if (score >= 20) {  
    this.passwordStrengthText='Weak';
    idx = 1;  
  }  
  this.passwordStatusColor=this.colors[idx]
  return {  
    idx: idx + 1,  
    col: this.colors[idx]  
  };  
}  
  ngOnChanges(changes: {[propName: string]: SimpleChange}): void {  
      var password = changes['passwordToCheck'].currentValue;  
      this.setBarColors(5, '#DDD');  
      if (password) {  
          let c = this.getColor(PasswordStrengthIndicatorComponent.measureStrength(password));  
          this.setBarColors(c.idx, c.col);  

      }  

  }  

  private setBarColors(count, col) {  
      for (let _n = 0; _n < count; _n++) {  
          this['bar' + _n] = col;  
      }  
  }  

}
