import {Injectable} from '@angular/core';
import { UserApiService } from './user-api.service';
@Injectable()
export class DataService {
  constructor(
      private apiService: UserApiService
  ) {

  }
  getCountApiCalled=false;
  postcodeValidation(event) {
   
    var code = ('charCode' in event) ? event.charCode : event.keyCode;
    if ((!(code == 32) && // space
        !(code==45) &&
      !(code > 47 && code < 58) && // numeric (0-9)
      !(code > 64 && code < 91) && // upper alpha (A-Z)
      !(code > 96 && code < 123))) { // lower alpha (a-z)
        event.preventDefault();
    }
  }

  
  secondsTohms(seconds) {
    seconds = Number(seconds);
    var h = Math.floor(seconds % (3600*24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);
    
    var hDisplay = h > 0 ? (h + (h == 1 ? " hour, " : " hours, ")) : "";
    var mDisplay = m > 0 ? (m + (m == 1 ? " minute, " : " minutes, ")) : "";
    var sDisplay = s > 0 ? (s + (s == 1 ? " second" : " seconds")) : "";
    return   hDisplay + mDisplay + sDisplay;
    }

    checkForTheValueInthetypeArray(obj,value){
      if(obj.types.filter(obj=>obj==value).length>0){
        return true
      }else{
        return false
      }
      
    }
    
    checKforStatus(array){
      let filtered=array.filter((obj)=>{
        return (obj.status=='active' && obj.currency!='EUR')
      })
      return (filtered.length==0 ? false:true);
    }
    
    getUTCDateTime(value){
      let getDate=value.getUTCDate().toString().length ==1 ? ('0'+(value.getUTCDate())):(value.getUTCDate());
      let getMonth=value.getUTCMonth().toString().length ==1 ? ('0'+(value.getUTCMonth()+1)):(value.getUTCMonth()+1);
      let getYear=value.getUTCFullYear();
      let hours=value.getUTCHours().toString().length ==1 ? ('0'+(value.getUTCHours())):(value.getUTCHours());
      let minutes=value.getUTCMinutes().toString().length ==1 ? ('0'+(value.getUTCMinutes())):(value.getUTCMinutes());
      let seconds=value.getUTCSeconds().toString().length ==1 ? ('0'+(value.getUTCSeconds())):(value.getUTCSeconds());
      return getYear+'-'+getMonth+'-'+getDate+' '+hours+':'+minutes+':'+seconds;
    }

    handleAddressChange(event,country){
      let splittedArrayObj={
        streetName:'',
        city:'',
        state:'',
        country:'',
        zipcode:''
      }
      event.address_components.forEach((obj,index) => {
          if(index==0 && !this.checkForTheValueInthetypeArray(obj,'locality') && 
          !this.checkForTheValueInthetypeArray(obj,'country')&& 
          !this.checkForTheValueInthetypeArray(obj,'postal_code')&&
          !this.checkForTheValueInthetypeArray(obj,'postal_town')&& 
          !this.checkForTheValueInthetypeArray(obj,'administrative_area_level_1')
          ){
            splittedArrayObj.streetName=obj.long_name;
          }else if(index>0 && !this.checkForTheValueInthetypeArray(obj,'locality') && 
          !this.checkForTheValueInthetypeArray(obj,'country')&& 
          !this.checkForTheValueInthetypeArray(obj,'postal_code')&& 
          !this.checkForTheValueInthetypeArray(obj,'postal_town')&& 
          !this.checkForTheValueInthetypeArray(obj,'administrative_area_level_1')){
            if(splittedArrayObj.streetName.length==0){
              splittedArrayObj.streetName=obj.long_name;
            }else{
              splittedArrayObj.streetName=splittedArrayObj.streetName+','+obj.long_name
            }
           
          }
          if(this.checkForTheValueInthetypeArray(obj,'locality')){
            splittedArrayObj.city=obj.long_name;
          }
          if(this.checkForTheValueInthetypeArray(obj,'postal_town')){
            splittedArrayObj.city=obj.long_name;
          }
          if(this.checkForTheValueInthetypeArray(obj,'administrative_area_level_1')){
            splittedArrayObj.state=obj.long_name;
          }
          if(this.checkForTheValueInthetypeArray(obj,'country')){
            splittedArrayObj.country=country.filter(countryObj=>countryObj.name==obj.long_name)[0].id;
          }
          if(this.checkForTheValueInthetypeArray(obj,'postal_code')){
            splittedArrayObj.zipcode=obj.long_name;
          }
      });
      if(splittedArrayObj.city==''&& splittedArrayObj.state!='' && splittedArrayObj.country!='United Kingdom'){
        splittedArrayObj.city=splittedArrayObj.state;
        splittedArrayObj.state='';
      }
      return splittedArrayObj;
  }
  

   validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
  }


  omit_special_char(event)
{   
   let k;  
   k = event.charCode;  //         k = event.keyCode;  (Both can be used)
   return((k >= 48 && k <= 57) || k== 46); 
}

   
  validEmail(value) {
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (value) {
        if (!value.match(regex)) {       
          return false;
        }        
        else {
         return true;
        }
    } 
    else {
      return true;
    }
  }


  checkForZero(event){
    if(event.target.value.length===0 && event.key=='0'){
      event.preventDefault();
    }
   }
   checkForZeroOnPaste(event){
     let clipboardData = event.clipboardData;
     let pastedText = clipboardData.getData('text');
     if(pastedText=='0'){
       event.preventDefault()
     }
   }

  getTextForOptions(value,textFor){
    if(textFor=="accountUsage" ){
      let usages=["Regularly","Every few months","For a set period of time","One time only"]
      return usages[value-1]
      // return Object.keys(this.usages)[value-1]
    }else if(textFor=="businessType" ){
      let businessTypes=["Private Company","Public Company","Government","Trust","NGO"]
      return businessTypes[value-1]
      // return Object.keys(this.businessTypes)[value-1]
    }else if(textFor=="businessRole"){
      let businessRoles=["Distributor","Trader","Agent","Vessel Owner","Broker","Other"]
      return businessRoles[value-1]
      // return Object.keys(this.businessRoles)[value-1]
    }else if(textFor=="businessActivity"){
      let businessActivity=["Trade Services & Contractors","Transport, Shipping & Logistics","Wholesale & Supplies (B2B)"]
      return businessActivity[value-1]
      // return Object.keys(this.businessActivityOptions)[value-1]
    }else if(textFor=="noOfEmployees"){
      let noOfEmployeess=["1 - 10","10 - 25","25 - 50","50 - 100","100+"]
      return noOfEmployeess[value-1]
      // return Object.keys(this.noOfEmployeesOptions)[value-1]
    }else if(textFor=="businessYearlyTurnover" ){
      let businessYearlyTurnovers=["0 - 20,000","20,001 - 150,000","150,001 - 250,000","250,001 - 500,000","500,001 - 1,000,000", "Over 1,000,000"]
      return businessYearlyTurnovers[value-1]
      // return Object.keys(this.businessYearlyturnOverOptions)[value-1]
    }
    
  }

  getCount() {
    if(!this.getCountApiCalled){
      this.getCountApiCalled=true;
      this.apiService.functionGET('admin/notification/getCount').subscribe((res) => {
        if (res['status']) {
          this.Acount = res['data']['notificationCount'];
          this.getCountApiCalled=false;
        }
      });
    }

  }

  selectedCompanyDetails:any;
  href;
  showAdditionalInfo = false;
  adminResellerType = null;
  reselelrRefId;
  loggedInUsertype;
  resellerName;
  resellerId;
  companyActiveTab='';
  resellarCreationActiveTab='';
  adminResellerAccount = 'iban';
  accountType = ''
  companyAccType = 'iban';
  resellerType=1;
  resellerLoginType;
  boardResolutionDetails={
    signedDirectorDetails:{
      firstName:'',
      lastName:'',
      email:''
    },
    boardResolutionDoc:<any>''
  }
  userOnboardStepUnsavedDetails={
    unsaved:false,
    goToSaveClicked:false,
    unsavedIn:1,
    unsavedLastScreen:1
  }
  businessAddtionalDetails={
    "businessType":'',
    "businessActivity":'',
    "businessRole":'',
    "numberOfEmployees":'',
    "businessYearlyturnOver":'',
    "corporateUrl":'',
    "companyAddress":''
    }
  onBoardIbanoffenUsage;
  resellerAccountType='iban';
  companyAccountType='iban';
  companyIdForAuthorization;
  resellerData:any={  };
    Acount:number=0;
    Rcount:number=0;
  currentUrl:any='';
  activated = 0;
  isEmployee = false;
  notificationCount = 0;
  additionalDoc:any = null;
  tfaPage:any = false;
  auth = {
    referenceId: '',
    otpType: 1,
    token: '',
    refreshToken:'',
    checkLog: false,
    clearCounter: <any>'',
    mail: '',
    profile: {
      email: '',
      phone: '',
      name: '',
      photo: ''
    },
    completedSections: []
  };
  detailsIndex = 0;
  reloaded = false;
  adminCompany = {
    id: '',
    noOfCorporates: 0,
    totalDirectors: []
  };
  employeeSignate = {
    id:''
  };
  kyc = {
    id: '',
    kyc: false,
    edd: {
      companyId: '',
      request: '',
      requestDetails: '',
      attachment: <any>'',
      pop: false,
      type:''
    },
    additionalDocs: [],
    questions:[]
  };
  viewDoc ={
file:<any>'',
pop: false,
url:''
  };
  viewEdd ={
    pop: false,
    id:''
      };
  widget = {
    id: '',
    'api_form_token': '',
    'language': 'en',
    'user_hash': '',
    'user_id': '',
    country: ''
  };
  resellerWidget = {
    id: '',
    'api_form_token': '',
    'language': 'en',
    'user_hash': '',
    'user_id': '',
    country: '',
    name:'',
    dob: <any>''
  };
  resellerOrganisationWidget = {
    id: '',
    'api_form_token': '',
    'language': 'en',
    'user_hash': '',
    'user_id': '',
    country: '',
    name:'',
    dob: <any>''
  };

  admin = {
    referenceId: '',
    companyId: '',
    ibanCompanyId: '',
    currencyIban: [],
    company: {
      name: '',
      address:'',
      lat:'',
      lng:'',
      phoneCode: '+41',
      email: '',
      password: '',
      contactNumber: '',
      incorporationDate: '',
      incorporationCountry: '',
      registrationNumber: '',
      streetName: '',
      city: '',
      state: '',
      zipcode: '',
      dunsNumber: '',
      vatNumber: '',
      logo: <any>'',
      logoPreview: <any>'',
      typeOfBusiness: 1,
      paymentRecieveCountries: [],
      paymentSendCountries: [],
      currency: {
        receive: [],
        send: []
      }
    },
    
    corporate: {
      beneficaryName: '',
      address1: '',
      address2: '',
      postcode: '',
      city: '',
      country: '',
      iban: '',
      bicOrSwift: '',
      accountNumber: '',
      type: '',
      document1: <any>'',
      document2: <any>'',
      document3: <any>'',
      document4: <any>'',
      document5: <any>'',
      document6: <any>'',
    },
    iban: {
      CH: false,
      CB: false,
      BE: false,
      FR: false,
      DE: false

    },
    tabs: {
      ch: false,
      cb: false,
      be: false,
      fr: false,
      de: false
    },
    currencyData: {
      chCurrency: [],
      cbCurrency: [],
      beCurrency: [],
      frCurrency: [],
      deCurrency: [],
      chModel: '',
      cbModel: '',
      beModel: <any>'',
      frModel: '',
      deModel: '',
      selectChModel: false,
      selectCbModel: false,
      selectBeModel: false,
      selectFrModel: false,
      selectDeModel: false,
      chReferenceId: '',
      cbReferenceId: '',
      beReferenceId: '',
      frReferenceId: '',
      deReferenceId: '',
    },
    ibanData: {
      currency: [],  
    }
  };
  pageCompleted = {
    type: false,
    operational: false,
    businessName: false,
    personalProfile: false,
    ibanCreation: false,
    organization: false,
    remarks: false,
    activationFee: false
  };
  resellerCompleted = {
    type: false,
    address: false,
    bankDetails: false,
    organization: false,
    comments: false,
  };
  directorArrayDetails = {
    directorArray: [],
    directorFullArray: [],
    directorIndex: 0,
    addedNumber: 0,
    skipped: false,
    id: '',
    successOrSkip: false
  };
  UBOArrayDetails = {
    UBOArray: [],
    UBOFullArray: [],
    UBOIndex: 0,
    addedNumber: 0,
    skipped: false,
    id: '',
    successOrSkip: false
  };
  details:any = {
    remarks: {
      remarkText: '',
      files: []
    },
    referenceId: '',
    companyId: '',
    ibanCompanyId: '',
    currencyIban: [],
    director: {
      guid:'',
      name: '',
      surname:'',
      email: '',
      dob: <any>'',
      streetName: '',
      city: '',
      type: [],
      state: '',
      zip: '',
      phoneCode: '+41',
      mobile: '',
      country: '',
      proofTypeId: -2,
      proofText: '',
      image: <any>'',
      imagePreview: <any>'',
      addressProof: <any>'',
    },
    company: {
      name: '',
      address: '',
      lat:'',
      lng:'',
      phoneCode: '+41',
      contactNumber: '',
      incorporationDate: '',
      incorporationCountry: '',
      registrationNumber: '',
      streetName: '',
      city: '',
      state: '',
      zipcode: '',
      dunsNumber: '',
      vatNumber: '',
      logo: <any>'',
      logoPreview: <any>'',
      typeOfBusiness: 0,
      paymentRecieveCountries: [],
      paymentSendCountries: [],
      document1: <any>'',
      document2: <any>'',
      document3: <any>'',
      document4: <any>'',
      document5: <any>'',
      document6: <any>'',
      bank: '',
      bankCity: '',
      bankPostcode: '',
      country: '',
      iban: '',
      type: [],
      bicOrSwift: '',
      accountNumber: '',
      currency: {
        receive: [],
        send: []
      }
    },
    corporate: {
      beneficaryName: '',
      address1: '',
      address2: '',
      postcode: '',
      city: '',
      country: '',
      iban: '',
      bicOrSwift: '',
      accountNumber: '',
      type: '',
      document1: <any>'',
      document2: <any>'',
      document3: <any>'',
      document4: <any>'',
    },
    iban: {
      CH: false,
      CB: false,
      BE: false,
      FR: false,
      DE: false
    },
    tabs: {
      ch: false,
      cb: false,
      be: false,
      fr: false,
      de: false
    },
    currencyData: {
      chCurrency: [],
      cbCurrency: [],
      beCurrency: [],
      frCurrency: [],
      deCurrency: [],
      chModel: '',
      cbModel: '',
      beModel: '',
      frModel: '',
      deModel: '',
      selectChModel: false,
      selectCbModel: false,
      selectBeModel: false,
      selectFrModel: false,
      selectDeModel: false,
      chReferenceId: '',
      cbReferenceId: '',
      beReferenceId: '',
      frReferenceId: '',
      deReferenceId: '',
    },
    ibanData: {
      currency: [],  
    }
  };
  corporate = {
    id: ''
  };
  employee = {
    pages: [],
    id: '',
    isEdit: false
  };
  getIdUrl='';
  mediaEndPoint: '';
  completedPagesIndex = [];
  isRegCompleted = false;
  header = {
    latitude: '',
    longitude: ''
  };

  clearDirector() {
    this.details.director = {
      guid:'',
      name: '',
      email: '',
      dob: <any>'',
      streetName: '',
      city: '',
      type: [],
      state: '',
      zip: '',
      phoneCode: '+41',
      mobile: '',
      country: '',
      proofTypeId: -2,
      proofText: '',
      image: <any>'',
      imagePreview: <any>'',
      addressProof: <any>'',
    };
    this.widget = {
      id: '',
      'api_form_token': '',
      'language': 'en',
      'user_hash': '',
      'user_id': '',
      country: ''
    };

    this.resellerWidget = {
      id: '',
      'api_form_token': '',
      'language': 'en',
      'user_hash': '',
      'user_id': '',
      country: '',
      name:'',
      dob: <any>''
    };
    this.resellerOrganisationWidget = {
      id: '',
      'api_form_token': '',
      'language': 'en',
      'user_hash': '',
      'user_id': '',
      country: '',
      name:'',
      dob: <any>''
    };
  }

  clearDetails() {
    this.pageCompleted = {
      type: false,
      operational: false,
      businessName: false,
      personalProfile: false,
      ibanCreation: false,
      organization: false,
      remarks: false,
      activationFee: false
    };
    this.resellerCompleted = {
      type: false,
      address: false,
      bankDetails: false,
      organization: false,
      comments: false,
    };
    this.widget = {
      id: '',
      'api_form_token': '',
      'language': 'en',
      'user_hash': '',
      'user_id': '',
      country: ''
    };
    this.resellerWidget = {
      id: '',
      'api_form_token': '',
      'language': 'en',
      'user_hash': '',
      'user_id': '',
      country: '',
      name:'',
      dob: <any>''
    };
    this.resellerOrganisationWidget = {
      id: '',
      'api_form_token': '',
      'language': 'en',
      'user_hash': '',
      'user_id': '',
      country: '',
      name:'',
      dob: <any>''
    };
    this.directorArrayDetails = {
      directorArray: [],
      directorFullArray: [],
      directorIndex: 0,
      addedNumber: 0,
      skipped: false,
      id: '',
      successOrSkip: false
    };
    this.details = {
      remarks: {
        remarkText: '',
        files: []
      },
      referenceId: '',
      companyId: '',
      ibanCompanyId: '',
      currencyIban: [],
      director: {
        guid:'',
        name: '',
        email: '',
        dob: <any>'',
        streetName: '',
        city: '',
        type: [],
        state: '',
        zip: '',
        phoneCode: '+41',
        mobile: '',
        country: '',
        proofTypeId: -2,
        proofText: '',
        image: <any>'',
        imagePreview: <any>'',
        addressProof: <any>''
      },
      company: {
        name: '',
        address:'',
        lat:'',
        lng:'',
        phoneCode: '+41',
        contactNumber: '',
        incorporationDate: '',
        incorporationCountry: '',
        registrationNumber: '',
        streetName: '',
        city: '',
        state: '',
        zipcode: '',
        dunsNumber: '',
        vatNumber: '',
        logo: <any>'',
        logoPreview: <any>'',
        typeOfBusiness: 0,
        paymentRecieveCountries: [],
        paymentSendCountries: [],
        bank: '',
        bankCity: '',
        bankPostcode: '',
        country: '',
        iban: '',
        bicOrSwift: '',
        accountNumber: '',
        document1: <any>'',
        document2: <any>'',
        document3: <any>'',
        document4: <any>'',
        document5: <any>'',
        document6: <any>'',
        type: [],
        currency: {
          receive: [],
          send: []
        }
      },
      corporate: {
        beneficaryName: '',
        address1: '',
        address2: '',
        postcode: '',
        city: '',
        country: '',
        iban: '',
        bicOrSwift: '',
        accountNumber: '',
        type: '',
        document1: <any>'',
        document2: <any>'',
        document3: <any>'',
        document4: <any>'',
      },
      iban: {
        CH: false,
        CB: false,
        BE: false,
        FR: false,
        DE: false
      },
      tabs: {
        ch: false,
        cb: false,
        be: false,
        fr: false,
        de: false
      },
      currencyData: {
        chCurrency: [],
        cbCurrency: [],
        beCurrency: [],
        frCurrency: [],
        deCurrency: [],
        chModel: '',
        cbModel: '',
        beModel: '',
        frModel: '',
        deModel: '',
        selectChModel: false,
        selectCbModel: false,
        selectBeModel: false,
        selectFrModel: false,
        selectDeModel: false,
        chReferenceId: '',
        cbReferenceId: '',
        beReferenceId: '',
        frReferenceId: '',
        deReferenceId: '',
      },
      ibanData: {
        currency: [],  
      }
    };
    this.admin = {
      referenceId: '',
      companyId: '',
      ibanCompanyId: '',
      currencyIban: [],
      company: {
        name: '',
        address:'',
        lat:'',
        lng:'',
        phoneCode: '+41',
        email: '',
        password: '',
        contactNumber: '',
        incorporationDate: '',
        incorporationCountry: '',
        registrationNumber: '',
        streetName: '',
        city: '',
        state: '',
        zipcode: '',
        dunsNumber: '',
        vatNumber: '',
        logo: <any>'',
        logoPreview: <any>'',
        typeOfBusiness: 1,
        paymentRecieveCountries: [],
        paymentSendCountries: [],
        currency: {
          receive: [],
          send: []
        }
      },
      corporate: {
        beneficaryName: '',
        address1: '',
        address2: '',
        postcode: '',
        city: '',
        country: '',
        iban: '',
        bicOrSwift: '',
        accountNumber: '',
        type: '',
        document1: <any>'',
        document2: <any>'',
        document3: <any>'',
        document4: <any>'',
        document5: <any>'',
        document6: <any>'',
      },
      iban: {
        CH: false,
        CB: false,
        BE: false,
        FR: false,
        DE: false
      },
      tabs: {
        ch: false,
        cb: false,
        be: false,
        fr: false,
        de: false
      },
      currencyData: {
        chCurrency: [],
        cbCurrency: [],
        beCurrency: [],
        frCurrency: [],
        deCurrency: [],
        chModel: '',
        cbModel: '',
        beModel: '',
        frModel: '',
        deModel: '',
        selectChModel: false,
        selectCbModel: false,
        selectBeModel: false,
        selectFrModel: false,
        selectDeModel: false,
        chReferenceId: '',
        cbReferenceId: '',
        beReferenceId: '',
        frReferenceId: '',
        deReferenceId: '',
      },
      ibanData: {
        currency: [],  
      }
    };
    this.boardResolutionDetails={
      signedDirectorDetails:{
        firstName:'',
        lastName:'',
        email:''
      },
      boardResolutionDoc:<any>''
    }
    this.businessAddtionalDetails={
      "businessType":'',
      "businessActivity":'',
      "businessRole":'',
      "numberOfEmployees":'',
      "businessYearlyturnOver":'',
      "corporateUrl":'',
      "companyAddress":''
      }
  }

  clearAuth() {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('name');
    sessionStorage.removeItem('email');
    sessionStorage.removeItem('isAdmin');
    sessionStorage.removeItem('isReseller');
    sessionStorage.removeItem('isRegistered');
    sessionStorage.removeItem('activated');
    sessionStorage.removeItem('completedPagesIndex');
    sessionStorage.removeItem('phone');
    if (sessionStorage.getItem('photo')) {
      sessionStorage.removeItem('photo');
    }
    sessionStorage.clear();
    this.reloaded = true;
    this.auth = {
      referenceId: '',
      otpType: 1,
      token: '',
      refreshToken:'',
      checkLog: false,
      mail: '',
      clearCounter: <any>'',
      profile: {
        email: '',
        phone: '',
        name: '',
        photo: ''
      },
      completedSections: []
    };
    this.activated = 0;
    this.clearDetails();
    this.corporate = {
      id: ''
    };
    this.employee = {
      pages: [],
      id: '',
      isEdit: false
    };
  }

  pageIndex(index) {
    if (this.completedPagesIndex.indexOf(index) === -1) {
      this.completedPagesIndex.push(index);
      try {
        sessionStorage.removeItem('completedPagesIndex');
        sessionStorage.setItem('completedPagesIndex', JSON.stringify(this.completedPagesIndex));
      } catch (e) {
      }
    }
  }

  completedRegCheck() {
    if (this.completedPagesIndex.length > 5) {
      if (this.completedPagesIndex.includes(7)) {
        return true;
      }
      return true;
    } else {
      return false;
    }
  }
}
