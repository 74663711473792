import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.css']
})
export class PopupComponent implements OnInit {

  @Output() closePopup = new  EventEmitter<boolean>();
  constructor() { }

  ngOnInit(): void {
  }
  closeEvent():void{ 
    this.closePopup.emit(false); 
  }
}
