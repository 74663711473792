import {Component, AfterViewInit, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {DataService} from '../services/data.service';
import {ToastrService} from 'ngx-toastr';
import {SpinnerService} from '@accubits/spinner';
import {LogoutService} from '../services/logout.service';
import { PermissionService } from '../services/permission.service';
import { UserApiService } from '../services/user-api.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  logoutPop = false;
  isAdmin;
  isReseller;
  count;
  Acount;
  Rcount;
  onboardingUser = false;

  constructor(public router: Router,
              public dataService: DataService,
              private toastr: ToastrService,
              private logService: LogoutService,
              public permissionService: PermissionService,
              private spinner: SpinnerService,
              private apiService: UserApiService) {
                if(router.url.includes('dashboard')){
                  this.permissionService.getCount();
                } else {
                  // this.getAdminNotificationCount();
                }
  }

  ngOnInit() {

    this.isReseller = sessionStorage.getItem('isReseller')=='true' ? true:false;
    this.isAdmin = sessionStorage.getItem('isAdmin')=='true' ? true:false; 
    this.onboardingUser = sessionStorage.getItem('accountRole')=='onboardingUser' ? true:false;
    try {
      if (!this.dataService.auth.profile.name) {
        this.dataService.auth.profile.name = sessionStorage.getItem('name');
        this.dataService.auth.profile.email = sessionStorage.getItem('email');
        if (sessionStorage.getItem('photo')) {
          this.dataService.auth.profile.photo = sessionStorage.getItem('photo');
        }
      }
      clearInterval(this.dataService.auth.clearCounter);
    } catch (e) {
    }
    this.dataService.auth.profile.name = sessionStorage.getItem('name');
        this.dataService.auth.profile.email = sessionStorage.getItem('email');
        if (sessionStorage.getItem('photo')) {
          this.dataService.auth.profile.photo = sessionStorage.getItem('photo');
        }
  }

  logoutUser() {
    this.logService.logoutUser().subscribe((res) => {
        if (res['status']) {
          this.logoutSuccess(res['message']);
        }
      }, (err) => {
        if (err.message && err.message !== 'No details found') {
          //this.toastr.error(err.message);
        } else if (err.errors) {
          for (const item of err.errors) {
            //this.toastr.error(item.msg);
          }
        }
        this.spinner.hide();
      }
    );
  }

  logoutAdmin() {
    this.logService.logoutAdmin().subscribe((res) => {
        if (res['status']) {
          this.logoutSuccess(res['message']);
        }
      }, (err) => {
        if (err.message && err.message !== 'No details found') {
          this.toastr.error(err.message);
        } else if (err.errors) {
          for (const item of err.errors) {
            this.toastr.error(item.msg);
          }
        }
        this.spinner.hide();
      }
    );
  }
  logoutReseller() {
    this.logService.logoutReseller().subscribe((res) => {
        if (res['status']) {
          this.logoutSuccess(res['message']);
        }
      }, (err) => {
        if (err.message && err.message !== 'No details found') {
          //this.toastr.error(err.message);
        } else if (err.errors) {
          for (const item of err.errors) {
           // this.toastr.error(item.msg);
          }
        }
        this.spinner.hide();
      }
    );
  }

  logoutSuccess(message) {
    this.dataService.clearAuth();
    setTimeout(() => {
      this.spinner.hide();
      this.logoutPop = !this.logoutPop;
      this.toastr.success(message);
      if (this.isAdmin == 'true') {
        this.router.navigateByUrl('/userLogin/admin');
      }
      else if(this.isReseller == 'true'){
        this.router.navigateByUrl('/userLogin/reseller');
      }else {
          this.router.navigateByUrl('/userLogin/login')
      };
    }, 500);
  }

  logout($event) {
    $event.stopPropagation();
    this.isAdmin = sessionStorage.getItem('isAdmin');
    this.isReseller = sessionStorage.getItem('isReseller');
    this.spinner.show();
    if (this.isAdmin == 'true') {
      this.logoutAdmin();
    } else if (this.isReseller == 'true') {
      this.logoutReseller();
    }else {
      this.logoutUser();
    }
    this.permissionService.apiResult = {};
    sessionStorage.clear();

  }
  getNotificationCount() {
    this.apiService.functionGET('notification/getCount').subscribe((res) => {
          if (res['status']) {
   this.count = res['data']['notificationCount'];
          }
        });
  }
  getAdminNotificationCount() {
    this.apiService.functionGET('admin/notification/getCount').subscribe((res) => {
          if (res['status']) {
   this.Acount = res['data']['notificationCount'];
          }
        });
  }
}